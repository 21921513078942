import { graphql } from "gatsby"
import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Hero } from "../components/Hero"
import { Container, Section } from "../components/Layouts"
import Portfolio from "../components/Portfolio/Portfolio"
import { Image, Text } from "../components/Core"
import Button from "../components/Buttons/Button"
import Contact from "../components/Services/contact"
import InsightsWidget from "../components/Widgets/Insights"

function OurWork({ data }) {
  const post = data.allUniquePagesJson.nodes[0]
  const {
    list,
    heroSection: { heading, blurb, desktopImage, mobileImage }
  } = post
  const ourWorkPages = data.ourWork.nodes

  return (
    <Layout navSpacer className="work">
      <SEO title={post.metaTitle} description={post.metaDescription} />
      <Hero
        noOverlapMobile
        heading={heading}
        desktopImage={desktopImage}
        mobileImage={mobileImage}
        blurb={blurb}
      />
      <Container>
        {ourWorkPages.map((row, i) => (
          <React.Fragment key={i}>
            <Section>
              <div className={`work__row ${i % 2 === 0 ? "" : "reverse"}`}>
                <div className="work__image">
                  <Image publicId={row.query.ourWorkImage} />
                </div>
                <div className="work__content">
                  <Text className="mb-1" as="h2" text={row.category} />
                  <Text text={row.query.blurb} />
                  <Button
                    buttonText="See Our Work"
                    href={`/${row.title}`}
                    destination="internal"
                    appearance="contained"
                  />
                </div>
              </div>
            </Section>
          </React.Fragment>
        ))}
      </Container>

      <Section zeroBottom>
        <Container>
          <InsightsWidget />
        </Container>
      </Section>

      <Section zeroBottom>
        <Container medium>
          <Contact />
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ourWorkPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heroSection {
          heading
          blurb
          desktopImage
          mobileImage
        }
      }
    }
    ourWork: allOurWorkJson(
      sort: { order: ASC, fields: order }
      filter: { hasThisPage: { eq: true } }
    ) {
      nodes {
        title
        category
        query {
          blurb
          ourWorkImage
        }
      }
    }
  }
`

export default OurWork
