import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import classNames from "classnames"
import { Section, Container } from "../Layouts"
import { Text, Image } from "../Core"
import UpRightArrow from "../../../static/images/up-right-arrow.svg"
import Button from "../Buttons/Button"
import MobileCarousel from "../Carousels/MobileCarousel"

const Portfolio = ({ bgColor, listColor }) => {
  const data = useStaticQuery(graphql`
    query portfolioQuery {
      allDataJson(filter: { optionsItem: { eq: "portfolio" } }) {
        nodes {
          subHeader
          topHeader
          categories {
            category
            blurb
            content {
              title
              link
            }
          }
          portfolioImagesList {
            image
          }
        }
      }
    }
  `)

  const portfolioData = data.allDataJson.nodes[0]
  const { categories, topHeader, subHeader, portfolioImagesList } =
    portfolioData

  const createId = str => {
    return str
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "")
  }

  const Categories = () => {
    return categories.map((categoryContent, i) => {
      const { category, blurb, content } = categoryContent
      return (
        <div>
          <div id={createId(category)} className="portfolio-category" key={i}>
            <Text
              as="p"
              text={category}
              className="portfolio-category__title"
            />
            <Text
              as="p"
              text={blurb}
              className="mb-1 mt-0 portfolio-category__blurb"
            />
            <div className="portfolio-category__content" key={i}>
              {content.map((item, i) => (
                <Button
                  buttonText={item.title}
                  href={item.link}
                  className="portfolio-category__button"
                  contained
                />
              ))}
            </div>
          </div>
        </div>
      )
    })
  }

  const mainClasses = classNames("portfolio-container", {
    "bg-tan": bgColor && bgColor.toLowerCase() === "tan",
    "list-white": listColor && listColor.toLowerCase() === "white"
  })

  return (
    <Section zeroBottom className={mainClasses}>
      <Container className="no-padding-touch">
        {/** DESKTOP VIEW*/}
        <div className="portfolio-container__grid is-hidden-touch">
          <div className="portfolio-container__header">
            <Text
              as="h2"
              text={topHeader}
              className="portfolio-container__left-header"
            />
            <div className="portfolio-container__right-header">
              <div className="portfolio-container__arrow">
                <UpRightArrow />
              </div>
              <Text as="h2" text={subHeader} />
            </div>
          </div>
          <div className="portfolio-category-container is-hidden-touch">
            <Categories />
          </div>
        </div>
        {/** MOBILE VIEW*/}
        <div className="is-hidden-desktop">
          <div>
            <Text
              as="h2"
              text={topHeader}
              className="portfolio-container__left-header"
            />
            <div className="portfolio-container__right-header">
              <Text as="h2" text={subHeader} className="mt-1" />
              <div className="portfolio-container__img-list">
                {portfolioImagesList.map((item, index) => (
                  <div key={index}>
                    <Image useAR={false} publicId={item.image} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <MobileCarousel>
            {categories.map((categoryContent, i) => {
              const { category, blurb, content } = categoryContent
              return (
                <div className="rounded portfolio-card mt-2" key={i}>
                  <Text
                    as="p"
                    text={category}
                    className="portfolio-category__title"
                  />
                  <Text
                    as="p"
                    text={blurb}
                    className="mt-1 portfolio-category__blurb"
                  />
                  <div className="portfolio-card__content" key={i}>
                    {content.map((item, i) => (
                      <Button
                        buttonText={item.title}
                        href={item.link}
                        contained
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </MobileCarousel>
        </div>
      </Container>
    </Section>
  )
}

export default Portfolio
